/* eslint-disable react/jsx-props-no-spreading */
// Header and Content Elements are optional to use

// @jsx jsx

// Used by tag @jsx
// needed for video sx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx, SxStyleProp } from "theme-ui"

import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text } from "@theme-ui/components"

export interface SectionPropTypes {
  as?: string
  backgroundComponent?: React.ReactNode
  backgroundMaxWidth?: number | number[] | string
  backgroundMaxHeight?: number | number[] | string
  backgroundImageData?: IGatsbyImageData;
  backgroundVideoSrc?: string
  backgroundColor?: string
  backgroundOffsetTopColor?: string
  backgroundOffsetTopSpace?: number
  backgroundOffsetBottomColor?: string
  backgroundOffsetBottomSpace?: number
  backgroundMediaMask?: string
  px?: number | (number | null)[]
  py?: number | (number | null)[]
  sx?: SxStyleProp
  contentSx?: SxStyleProp
  children?: React.ReactNode
}

export interface SectionHeaderPropTypes {
  title: string
  titleTextColor?: string
  subtitle?: string
  subtitleTextColor?: string
  sx?: SxStyleProp
}

export const Header: React.FC<SectionHeaderPropTypes> = ({
  title,
  titleTextColor = "black",
  subtitle,
  subtitleTextColor = "black",
  sx,
}: SectionHeaderPropTypes) => {
  return (
    <Box
      role="heading"
      sx={{
        ...sx,
      }}
    >
      <Heading
        as="h1"
        variant="section.title"
        aria-label={`${title}${subtitle ? ` ${subtitle}` : ""}`}
        sx={{
          color: titleTextColor,
        }}
      >
        {title}
        {subtitle && (
          <Text
            as="p"
            variant="h2"
            aria-hidden="true"
            sx={{
              color: subtitleTextColor,
              pt: 4,
            }}
          >
            {subtitle}
          </Text>
        )}
      </Heading>
    </Box>
  )
}

interface BackgroundMediaPropTypes {
  backgroundComponent?: React.ReactNode
  backgroundImageData?: IGatsbyImageData
  backgroundVideoSrc?: string
  maxWidth?: number | number[] | string
  maxHeight?: number | number[] | string
  mask?: string
}
const BackgroundMedia: React.FC<BackgroundMediaPropTypes> = ({
  backgroundComponent,
  backgroundImageData,
  backgroundVideoSrc,
  maxWidth,
  maxHeight,
  mask,
}: BackgroundMediaPropTypes) => {
  if (!backgroundImageData && !backgroundVideoSrc && !backgroundComponent)
    return null

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        maxWidth,
        maxHeight,
        position: "relative",
        clipPath: mask,
      }}
    >
      {Boolean(backgroundComponent) && (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {backgroundComponent}
        </Box>
      )}
      {backgroundImageData && (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GatsbyImage
            image={backgroundImageData}
            alt=""
            style={{ height: "100%", width: "100%" }}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "50% 0",
            }}
          />
        </Box>
      )}
      {backgroundVideoSrc && (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <video
            autoPlay
            playsInline
            muted
            loop
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "50% 0",
            }}
          >
            <source src={backgroundVideoSrc} type="video/mp4" />
          </video>
        </Box>
      )}
    </Box>
  )
}
const Section: React.FC<SectionPropTypes> = ({
  as = "section",
  backgroundComponent,
  backgroundMaxWidth = 7,
  backgroundMaxHeight = "1500px",
  backgroundColor,
  backgroundImageData,
  backgroundVideoSrc,
  backgroundOffsetTopColor,
  backgroundOffsetTopSpace = 0,
  backgroundOffsetBottomColor,
  backgroundOffsetBottomSpace = 0,
  backgroundMediaMask,
  px = [3, 7],
  py = 14,
  sx,
  contentSx,
  children,
  ...props
}: SectionPropTypes) => {
  return (
    <Box
      as={as}
      sx={{
        position: "relative",
        display: "flex",
        ...sx,
      }}
    >
      {Boolean(backgroundOffsetTopSpace) && (
        <Box
          bg={backgroundOffsetTopColor}
          sx={{
            position: "absolute",
            zIndex: "-1",
            width: "100%",
            top: 0,
            pt: backgroundOffsetTopSpace,
          }}
        />
      )}
      {(backgroundImageData ||
        backgroundVideoSrc ||
        backgroundColor ||
        backgroundComponent) && (
        <Flex
          bg={backgroundColor}
          sx={{
            position: "absolute",
            zIndex: "-2",
            height: "100%",
            width: "100%",
            paddingTop: backgroundOffsetTopSpace,
            paddingBottom: backgroundOffsetBottomSpace,
          }}
        >
          <BackgroundMedia
            backgroundImageData={backgroundImageData}
            backgroundVideoSrc={backgroundVideoSrc}
            backgroundComponent={backgroundComponent}
            maxWidth={backgroundMaxWidth}
            maxHeight={backgroundMaxHeight}
            mask={backgroundMediaMask}
          />
        </Flex>
      )}
      {Boolean(backgroundOffsetBottomSpace) && (
        <Box
          bg={backgroundOffsetBottomColor}
          sx={{
            position: "absolute",
            zIndex: "-1",
            width: "100%",
            bottom: 0,
            pb: backgroundOffsetBottomSpace,
          }}
        />
      )}
      <Box
        {...props}
        px={px}
        py={py}
        sx={{
          flex: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: 5,
            margin: "0 auto",
            // display: 'grid',
            // gridTemplateColumns: '1fr',
            // gridTemplateRows: 'auto',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            ...contentSx,
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Section
