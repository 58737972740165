import React from "react"
import { Image } from "@theme-ui/components"
import { SxProps } from "theme-ui"
import logoPath from "./daniel_mazzola.svg"

export interface LogoProps {
  sx?: SxProps
}
const Logo: React.FC<LogoProps> = ({ sx }: LogoProps) => (
  <Image sx={sx} src={logoPath} alt="Daniel Mazolla Logo" />
)

export default Logo
